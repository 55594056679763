import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const InstagramFeed = (props) => {

  let token = 'IGQVJVb2N5b3BvaHdnZA2R5NkE3Uk9YSENMY1d3VFZAmVkN1ZATdqRVAwaXlPNjlDUmp6YlVhVHBsNTVsN0pSaVlHQldyYzM4Q2IxZAUc3NFlzRlUyMTRaREFPdXJuMjBybFU3ZAWNFZAjJ2aGkwSHVQWHVVUQZDZD';

  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [feed, setFeed] = useState(null);
  
  useEffect(() => {

    let url = 'https://graph.instagram.com/me/media?fields=media_count,media_type,permalink,thumbnail_url,media_url&&access_token=' + token;

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then(data => {
        if(data.hasOwnProperty('error')) {
          setIsLoaded(true);
          setIsError(true);
        } else {
          setIsLoaded(true);
          setIsError(false);
          setFeed(data.data);
        }
    })
    .catch((error) => {
      console.error('Error:', error);
      setIsLoaded(true);
      setIsError(true);
    });
  }, []);

  
  const Feed = (props) => {
    const {isLoaded, isError, feed} = props;
    if(!isLoaded || isError || feed === null) return ( <div></div> )

    let f = feed.slice(0, 10).map((feedItem, index) => {
      return (
        <a key={index} className="feed-item" href={feedItem.permalink}>
          <img
            src={feedItem.media_type === 'VIDEO' ? feedItem.thumbnail_url : feedItem.media_url}
            alt="description"
          />
        </a>
      )
    })

    return (
      <div id="instagram-feed">{f}</div>
    )
  }

  return (
    <Feed isError={isError} isLoaded={isLoaded} feed={feed}/>
  )
}


document.addEventListener('DOMContentLoaded', () => {
  let container = document.getElementById('instagram-feed-container');
  let hasLoaded = false;
  
  document.addEventListener('scroll', function () {
    if(!hasLoaded && shouldLoadInsta(container)) {
      if(!hasLoaded) hasLoaded = true;
      ReactDOM.render(
        <InstagramFeed/>,
        container.appendChild(document.createElement('div')),
      )
      container.classList.add('loaded');
    }
  }, {passive: true});

  const shouldLoadInsta = (el) => {
    const rect = el.getBoundingClientRect();
    const buffer = 200;

    return (
      (rect.top >= 0 && rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight)+rect.height+buffer))
      ||
      (rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
    );
  }

});

